(function (wnd) {
    function MuravidekTask(game, params) {
        try {
            this.game = game;
            this.parts = [];
            this.parts = [];
            this.timeLimit = 0;
            this.title = null;
            this.instructions = null;
            this.maxPoints = 0;
            this.enabled = true;
            this.interval = null;
            this.intervalValue = 0;
            this.loop = 0;
            this.points = 0;
            this.buttonRow = null;
            this.last = false;
            this.image = null;
            this.retinaImage = null;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error)
        }
    }

    MuravidekTask.prototype = {
        game: null,
        init: function () {
            
        },
        setParams: function (params) {
            if (params.title) this.setTitle(params.title);
            if (params.instructions) this.setInstuctions(params.instructions);
            if (params.maxPoints) this.setMaxPoints(params.maxPoints);
            if (params.parts) this.setParts(params.parts);
            if (params.timeLimit) this.setTimeLimit(params.timeLimit);
            if (params.image) this.setImage(params.image);
            if (params.retinaImage) this.setRetinaImage(params.retinaImage);
        },
        draw: function () {
            if (this.parts.length === 0) {
                return;
            }
            
            for (var i = 0; i < this.parts.length; i++) {
                var part = this.parts[i];
                this.game.taskContainer.append(part.getHTML());

                var gameHandler = typeof part.getGameHandler === 'function' ? part.getGameHandler() : null;
                if (gameHandler) {
                    if (typeof gameHandler.getHTML === 'function') {
                        this.game.taskContainer.append(gameHandler.getHTML());
                    }

                    if (typeof gameHandler.init === 'function') {
                        gameHandler.init();
                    }
                }
            }

            if (!this.timeLimit) {
                this.drawNavigationButtons('check');
            }
        },
        start: function () {
            this.loop++;
            this.enabled = true;

            for (var i = 0; i < this.parts.length; i++) {
                if (typeof this.parts[i].start === 'function') {
                    this.parts[i].start();
                }
            }

            if (this.timeLimit) {
                var _self = this;
                this.intervalValue = this.timeLimit;
                this.setTimerContent();
                this.game.timerItem.removeClass('hidden');

                this.interval = setInterval(function() {
                    _self.handleInterval();
                }, 1000);

                if (this.buttonRow) {
                    this.buttonRow.hide();
                }
            } else {
                this.game.timerItem.addClass('hidden');

                this.drawNavigationButtons('check');
            }
        },
        stop: function (stopped) {
            var finished = true;
            this.enabled = false;

            if (this.timeLimit) {
                for (var i = 0; i < this.parts.length; i++) {
                    if (typeof this.parts[i].stop === 'function') {
                        this.parts[i].stop();
                    }
    
                    if (!this.parts[i].finished) {
                        finished = false;
                    }
                }
            }

            if (!finished && !stopped) {
                return;
            }

            if (this.timeLimit && this.interval) {
                clearTimeout(this.interval);
                this.interval = null;
            }

            this.validate();
            this.updatePoints();
        },
        reveal: function () {
            this.enabled = false;

            $('html, body').animate({scrollTop: $('[data-purpose="task-container"]').offset().top}, 300);
            for (var i = 0; i < this.parts.length; i++) {
                if (typeof this.parts[i].reveal === 'function') {
                    this.parts[i].reveal();
                }
            }

            this.drawNavigationButtons('next');
        },
        validate: function () {
            var valid = true;

            for (var i = 0; i < this.parts.length; i++) {
                if (!this.parts[i].validate()) {
                    valid = false;
                }
            }

            this.drawNavigationButtons(valid ? 'next' : 'invalid');

            return valid;
        },
        destroy: function () {
            for (var i = 0; i < this.parts.length; i++) {
                if (typeof this.parts[i].destroy === 'function') {
                    this.parts[i].destroy();
                }

                if (typeof this.parts[i].getGameHandler === 'function') {
                    var gameHandler = this.parts[i].getGameHandler();
                    if (gameHandler) {
                        gameHandler.destroy();
                        delete(this.gameHandler);
                    }
                }
            }

            this.destroyButtons();
        },
        destroyButtons: function () {
            if (this.buttonRow) {
                this.buttonRow.find('.navigation-item').off();
                this.buttonRow.remove();
                this.buttonRow = null;
            }
        },
        setParts: function (parts) {
            if (!Array.isArray(parts)) {
                return;
            }

            for (var i = 0; i < parts.length; i++) {
                var part = parts[i];
                var partObj = null;

                switch (part.type) {
                    case 'memory':
                        partObj = new MemoryGame(this, part);
                        break;
                    case 'toggle':
                        partObj = new ToggleGame(this, part);
                        break;
                    case 'default':
                        partObj = new DefaultGame(this, part);
                        break;
                    case 'question_answer':
                        partObj = new QuestionAnswerGame(this, part);
                        break;
                    case 'spelling':
                        partObj = new SpellingGame(this, part);
                        break;
                    default:
                        throw Error('Handle game type: ' + part.type);
                }

                if (typeof part.handler !== 'undefined') {
                    if (typeof partObj.initGameHandler !== 'function') {
                        throw Error('Game handler function not implemented in this type: ' + part.type);
                    }

                    partObj.initGameHandler(part.handler);
                }

                if (partObj) {
                    this.parts.push(partObj);
                }
            }
        },
        setTitle: function (title) {
            this.title = title;
        },
        getTitle: function () {
            return this.title;
        },
        setInstuctions: function (instructions) {
            this.instructions = instructions;
        },
        getInstructions: function () {
            return this.instructions;
        },
        setMaxPoints: function (maxPoints) {
            this.maxPoints = maxPoints;
        },
        getMaxPoints: function () {
            return this.maxPoints;
        },
        getText: function (key) {
            return this.game.getText(key);
        },
        setTimeLimit: function (timeLimit) {
            this.timeLimit = parseFloat(timeLimit) * 1000;
        },
        handleInterval: function() {
            this.intervalValue -= 1000;
            if (this.intervalValue <= 0) {
                clearInterval(this.interval);
                this.interval = null;
                var stop = true;

                for (var i = 0; i < this.parts.length; i++) {
                    if (typeof this.parts[i].intervalEnded === 'function') {
                        var valid = this.parts[i].intervalEnded();
                        if (valid)  {
                            stop = false;
                        }
                    }
                }

                if (stop) {
                    this.stop(true);
                }
            }

            this.setTimerContent();
        },
        setTimerContent: function () {
            var minutes = Math.floor(this.intervalValue / 60000);
            var seconds = Math.floor((this.intervalValue % 60000) / 1000);

            if (minutes < 10) {
                minutes = '0' + minutes;
            }

            if (seconds < 10) {
                seconds = '0' + seconds;
            }

            this.game.setTimerValue(minutes + ' : ' + seconds);
        },
        getMultiplier: function () {
            if (this.loop === 1) {
                return 1;
            }

            if (this.loop === 2) {
                return 0.5;
            }

            return 0;
        },
        getPoints: function () {
            var points = 0;
            for (var i = 0; i < this.parts.length; i++) {
                points += parseInt(this.parts[i].getPoints());
            }

            return points;
        },
        updatePoints: function () {
            this.game.updatePoints();
        },
        setLast: function (last) {
            this.last = last;
        },
        setImage: function (image) {
            this.image = image;
        },
        getImage: function () {
            return this.image;
        },
        setRetinaImage: function (retinaImage) {
            this.retinaImage = retinaImage;
        },
        getRetinaImage: function () {
            return this.retinaImage;
        },
        drawNavigationButtons: function (type) {
            var _self = this;
            if (null !== this.buttonRow) {
                this.destroyButtons();
            }

            this.buttonRow = $('<div>', {
                class: 'navigation-row display-flex align-items-center justify-flex-end',
            });

            switch (type) {
                case 'check':
                    this.buttonRow.append($('<button>', {
                        type: 'button',
                        class: 'button navigation-item validate-button',
                        html: '<span class="text">' + this.getText('validate_button_label') + '</span>' + svg('arrow-right'),
                    }));

                    this.buttonRow.find('.validate-button').on('click', function () {
                        _self.stop();
                    });
                    break;
                case 'next':
                    var label = this.last ? 'Eredmény' : this.getText('next_step_button_label');
                    this.buttonRow.append($('<button>', {
                        type: 'button',
                        class: 'button navigation-item next-button',
                        html: '<span class="text">' + label + '</span>' + svg('arrow-right'),
                    }));

                    this.buttonRow.find('.next-button').on('click', function () {
                        _self.game.goToNextStep();
                    });
                    break;
                case 'invalid':
                    this.buttonRow.append($('<button>', {
                        type: 'button',
                        class: 'button beige navigation-item repeat-button',
                        html: '<span class="text">' + this.getText('repeat_button_label') + '</span>' + svg('repeat-icon'),
                    }));

                    if (this.needRevealButton()) {
                        this.buttonRow.append($('<button>', {
                            type: 'button',
                            class: 'button navigation-item reveal-button brown ghost',
                            html: '<span class="text">' + this.getText('reveal_button_label') + '</span>' + svg('reveal-icon'),
                        }));

                        this.buttonRow.find('.reveal-button').on('click', function () {
                            _self.reveal();
                        });
                    }

                    this.buttonRow.find('.repeat-button').on('click', function () {
                        _self.start();
                    });
                    
                    break;
                default:
                    console.log('handle button row type: ' + type);
                    break;
            }

            this.game.taskContainer.append(this.buttonRow);
        },
        needRevealButton: function () {
            for (var i = 0; i < this.parts.length; i++) {
                if (typeof this.parts[i].reveal === 'function') {
                    return true;
                }
            }

            return false;
        },
        isEnabled: function () {
            return this.enabled;
        },
    };

    wnd.MuravidekTask = MuravidekTask;
})(window);