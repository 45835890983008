(function (wnd) {
    function TagItem(parent, params) {
        try {
            this.parent = parent;   
            this.label = null;
            this.key = null;
            this.value = null;
            this.locked = null;
            this.point = 2;
            this.content = null;
            this.empty = true;
            this.multipleEnabled = false;
            
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    TagItem.prototype = {
        init: function () {
            if (this.value) {
                this.locked = true;
            }
        },
        addDOMEvents: function () {
            var _self = this;

            this.content.find('.tag-field').on('muravidek.tag_dropped', function () {
                _self.checkCurrentState();
            });
        },
        checkCurrentState: function () {
            if (this.locked) {
                return;
            }

            var tags = this.content.find('.tag-item');
            this.setEmpty(tags.length <= 0);

            if (this.isEmpty()) {
                this.setValue(null);
                return;
            }

            var value = $(tags[0]).data('value');      
            this.setValue(value);
        },
        setParams: function (params) {
            if (params.point) this.setPoint(params.point);
            if (params.key) this.setKey(params.key);
            if (params.label) this.setLabel(params.label);
            if (params.value) this.setValue(params.value);
        },
        start: function () {
            this.removeFeedback();
        },
        destroy: function () {
            this.content.find('.tag-field').off('muravidek.tag_dropped');
            this.content.remove();
        },
        reveal: function (value) {
            if (this.isLocked()) {
                return;
            }

            this.removeFeedback();
            this.content.find('.tag-field').addClass('revealed');
            this.content.find('.tag-field').append($('<div>', {
                class: 'tag-item',
                html: '<div class="text">' + this.parent.getText(value) + '</div>',
            }));
        },
        mark: function (success) {
            if (this.locked) {
                return;
            }

            this.locked = success;
            var feedback = success ? 'success' : 'error';
            this.removeFeedback();

            this.content.find('.tag-field').addClass(feedback);

            if (this.locked) {
                this.content.find('.tag-item').addClass('locked');
            }
        },
        removeFeedback: function () {
            this.content.find('.tag-field').removeClass('error');
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: 'tag-outer',
            });

            if (this.label) {
                this.content.append('<div class="field-label">' + this.parent.getText(this.label) + '</div>');
            }

            var item = $('<div>', {
                class: 'tag-field',
            });

            if (this.value) {
                this.setEmpty(false);
                item.addClass('locked');
                item.append('<div class="tag-item hint locked">' + this.parent.getText(this.value) + '</div>');
            }

            this.content.append(item);

            if (!this.value) {
                this.addDOMEvents();
            }

            return this.content;
        },
        setKey: function (key) {
            this.key = key;
        },
        setLabel: function (label) {
            this.label = label;
        },
        setValue: function (value) {
            this.value = value
        },
        setPoint: function (point) {
            this.point = point;
        },
        getPoint: function () {
            return this.point;
        },
        setEmpty: function (empty) {
            this.empty = empty;
        },
        isEmpty: function () {
            return this.empty;
        },
        isLocked: function () {
            return this.locked;
        },
        isMultipleEnabled: function () {
            return this.multipleEnabled;
        }
    }

    wnd.MuravidekTagItem = TagItem;
})(window);