(function (wnd) {
    function DragAndDropHandler(task, params) {
        try {
            this.task = task;
            this.fields = [];
            this.tags = [];
            this.sourceIsCloud = false;
            this.selectors = {
                tag: '.tag-item',
                zone: '.tag-field',
                container: '.tags-container',
            };

            this.options = {
                shuffleTags: true,
                multiple: false,
            };

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    DragAndDropHandler.prototype = {
        init: function () {
            var _self = this;
            
            $(this.getSelector('tag')).on('touchstart', function() {
                $('body').css({
                    overflow: 'hidden'
                });
            });

            $(this.getSelector('tag')).on('touchend', function() {
                $('body').css({
                    overflow: 'auto'
                });
            });

            $(this.getSelector('tag')).on('touchmove', function(e) {
                e.preventDefault();
            });

            interact(this.getSelector('tag')).draggable({
                inertia: true,
                autoScroll: true,
                listeners: {
                    start: function (event) {
                        _self.sourceIsCloud = event.target.parentNode.classList.contains('tags-container');
                    },
                    move: function (event) {
                        var target = event.target;
                        if (target.parentNode.classList.contains('success') || target.parentNode.classList.contains('locked') || !_self.task.isEnabled()) {
                            return;
                        }

                        if (!target.classList.contains('dragging')) {
                            target.classList.add('dragging');
                        }

                        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);
                    },
                    end: function (event) {
                        try {
                            var target = event.relatedTarget.querySelector(_self.getSelector('tag'));
                            target.style.transform = 'none';
                            target.removeAttribute('data-x');
                            target.removeAttribute('data-y');

                            if (target.classList.contains('dragging')) {
                                target.classList.remove('dragging');
                            }
                        } catch {}
                        
                        event.target.style.transform = 'none';
                        event.target.removeAttribute('data-x');
                        event.target.removeAttribute('data-y');

                        if (event.target.classList.contains('dragging')) {
                            event.target.classList.remove('dragging');
                        }
                    }
                },
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: '.game-section',
                        endOnly: true,
                    })
                ]
            });

            interact(this.getSelector('zone')).dropzone({
                accept: this.getSelector('tag'),
                overlap: 0.3,
                ondrop: function (event) {
                    var field = $(event.target);
                    if (field.hasClass('success') || field.hasClass('locked')) {
                        return;
                    }

                    var tag = $(event.relatedTarget);
                    if (!field.hasClass('multiple-enabled')) {
                        var currentTag = field.find(_self.getSelector('tag'));
                        if (currentTag.length > 0) {
                            if (_self.isMultiple()) {
                                currentTag.remove();
                            } else  {
                                $(_self.getSelector('container')).append(currentTag);
                            }
                        }
                    }
                    
                    if (!_self.isMultiple() || !_self.sourceIsCloud) {
                        field.append(tag);
                    } else {
                        var clone = tag.clone();
                        field.append(clone);
                    }

                    $(_self.getSelector('zone') + ':not(.success)').each(function() {
                        $(this).trigger('muravidek.tag_dropped');
                    });
                },
            });
        },
        setParams: function (params) {
            if (params.fields) this.setFields(params.fields);
            if (params.options) this.setOptions(params.options);
            if (params.selectors) this.setSelectors(params.selectors);
        },
        getHTML: function () {
            this.content = $('<div>', {
                class: this.getSelector('container').replace('.', '') + ' display-flex flex-wrap',
            });
            
            var tags = this.options.shuffleTags ? shuffleArray(this.tags) : this.tags;
            for (var i = 0; i < tags.length; i++) {
                this.content.append($('<div>', {
                    class: this.getSelector('tag').replace('.', ''),
                    html: '<div class="text">' + this.task.getText(tags[i]) + '</div>',
                    'data-value': tags[i],
                }));
            }

            return this.content;
        },
        setFields: function (fields) {
            if (!Array.isArray(fields)) {
                return;
            }

            for (var i = 0; i < fields.length; i++) {
                if (fields[i].type === 'tag') {
                    this.fields.push(fields[i]);
                    
                    for (var j = 0; j < fields[i].options.length; j++) {
                        this.tags.push(fields[i].options[j])
                    }
                }
            }
        },
        destroy: function () {
            $(this.getSelector('tag')).off('touchstart');
            $(this.getSelector('tag')).off('touchend');
            $(this.getSelector('tag')).off('touchmove');
            
            interact(this.getSelector('tag')).unset();
            interact(this.getSelector('zone')).unset();
        },
        setSelectors: function (selectors) {
            this.selectors = $.extend({}, this.selectors, selectors);
        },
        getSelector: function (key) {
            return this.selectors[key] ? this.selectors[key] : key
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        isMultiple: function () {
            return this.options.multiple;
        }
    };

    wnd.DragAndDropHandler = DragAndDropHandler;
})(window);