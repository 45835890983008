(function(wnd) {
    function SingleTextItem(task, params) {
        try {
            this.task = task;
            this.expectedValue = null;
            this.dynamic = false;
            this.content = null;
            this.input = null;
            this.locked = false;
            this.point = 2;

            if (params) {
                this.setParams(params);
            }
        } catch (error) {
            console.error(error);
        }
    }

    SingleTextItem.prototype = {
        addDOMEvents: function () {
            var _self = this;
            this.input.on('keypress', function (evt) {
                if (_self.isLocked() || !_self.task.isEnabled()) {
                    evt.preventDefault();
                }
            })

            if (this.isDynamic()) {
                this.input.on('keyup', function () {
                    var value = $(this).val();

                    $(this).css({
                        width: value.length + 'ch',
                    });
                });
            }
        },
        setParams: function (params) {
            if (params.expectedValue) this.setExpectedValue(params.expectedValue);
            if (typeof params.dynamic !== 'undefined') this.setDynamic(params.dynamic);
        },
        getHTML: function () {
            this.content = $('<label>', {
                class: 'single-text-item',
            });

            this.input = $('<input>', {
                type: 'text',
                class: 'field ' + this.isDynamic('dynamic-field'),
            });
            
            this.content.append(this.input)

            this.addDOMEvents();

            return this.content;
        },
        start: function () {
            this.removeFeedback();
        },
        stop: function () {},
        reveal: function (value) {
            if (this.isLocked()) {
                return;
            }

            if (!value) {
                value = this.expectedValue;
            }

            this.removeFeedback();
            this.content.addClass('revealed');
            this.input.val(value).trigger('keyup');
        },
        destroy: function () {
            this.input.off();
            this.content.remove();
        },
        mark: function (success) {
            this.locked = success;
            var feedback = success ? 'success' : 'error';

            this.content.addClass(feedback);
            if (success) {
                this.input.attr('readonly', true);
            }
        },
        removeFeedback: function () {
            this.content.removeClass('error');
        },
        setExpectedValue: function (expectedValue) {
            this.expectedValue = expectedValue;
        },
        getExpectedValue: function () {
            return this.expectedValue;
        },
        setDynamic: function (dynamic) {
            this.dynamic = dynamic;
        },
        isDynamic: function () {
            return this.dynamic;
        },
        setLocked: function (locked) {
            this.locked;
        },
        isLocked: function () {
            return this.locked;
        },
        getValue: function () {
            return this.input.val();
        },
        getPoint: function () {
            return this.point;
        },
    };

    wnd.SingleTextItem = SingleTextItem;
})(window);